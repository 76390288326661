<template>
  <div class="login">
    <div class="login__container">
      <h1 class="login__title">Login</h1>
      <form @submit.prevent="handleSubmit">
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'email'"
            :label="'email'"
            :type="'email'"
            :placeholder="'Email'"
            :error-messages="validationErrors['email']"
            isBordered
            isAverage
          />
        </div>
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'password'"
            :label="'password'"
            :type="'password'"
            :placeholder="'Password'"
            :error-messages="validationErrors['password']"
            isBordered
            isAverage
          />
        </div>
        <div v-if="error.message != undefined" class="form-create__error">
          {{ error.message }}
        </div>
        <MainButton :type="'submit'"> Log In </MainButton>
      </form>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getAccessToken } from "~/helpers/userAuth";

import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";

export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      formDataToSend: {},
    };
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch("users/login", this.formDataToSend);
    },
  },
  mounted() {
    if (getAccessToken() != undefined) {
      this.$router.push("/");
    }
  },
  components: {
    MainButton,
    InputText,
    FetchSpinnerModal,
  },
  computed: {
    ...mapState("users", ["error", "validationErrors", "userInfo", "loadingProcess"]),
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__container {
    width: 400px;
    padding-bottom: 150px;
  }

  &__title {
    padding-bottom: 20px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: $coffee;
  }

  &__choose-site {
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }
}
.input-container {
  padding-bottom: 20px;
}
</style>
